<template>
  <div>
    <b-modal
      id="modal-status-perjanjian-kerja"
      size="lg"
      centered
      title="Update Dokumen Surat Perjanjian Kerja"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Pilih Status <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="dataEdit.status"
            :options="options"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="status"
            label="text"
            placeholder="-- Pilih Status --"
            @input="dataEdit.statusPerjanjianAktif = dataEdit.status.status"
          ></multiselect>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-status-perjanjian-kerja')"
        >
          {{ $store.state.form.batal }}
        </b-button>
        <b-button variant="primary" :disabled="busy" @click="upload">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: { Multiselect },
  props: ["data", "detail"],
  data() {
    return {
      dataEdit: {
        status: "",
        statusPerjanjianAktif: "",
        id: "",
      },
      busy: false,
      button: "Simpan",
      options: [
        { status: 0, text: "Aktif" },
        { status: 1, text: "Selesai /Habis Kontrak" },
        { status: 2, text: "Diberhentikan" },
        { status: 3, text: "Resign" },
        // { status: 4, text: "Diperpanjang" },
        // { status: 5, text: "Mutasi Jabatan" },
        { status: 6, text: "Meninggal" },
        { status: 7, text: "Hilang / Tanpa Keterangan" },
        { status: 9, text: "Pensiun" },
      ],
    };
  },
  methods: {
    async upload() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      let links = "";
      vm.dataEdit.dataKaryawanId = vm.data.dataKaryawanId
      vm.dataEdit.masterGajiId = vm.data.masterGajiId
      vm.dataEdit.nominalGajiPerjanjian = vm.data.nominalGajiPerjanjian
      console.log(vm.dataEdit.statusPerjanjianAktif);
      if (vm.dataEdit.statusPerjanjianAktif == 0) {
        links = "suratPerjanjianKerja/update";
      } else {
        links = "suratPerJanjianKerja/updateStatusNonAktif";
        vm.dataEdit.tanggalNonAktif = moment().format("YYYY-MM-DD");
      }
      vm.dataEdit.id = vm.data.suratPerjanjianKerjaId;
      vm.dataEdit.suratPerjanjianKerjaId = vm.data.suratPerjanjianKerjaId;
      vm.busy = true;
      console.log(vm.dataEdit);
      let upload = await axios.post(ipBackend + links, vm.dataEdit);
      console.log(upload.data, "perjanjian");
      if (upload.data.status == 200) {
        if (upload.data.message == "sukses") {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "success",
            msg: "BERHASIL UPDATE STATUS PERJANJIAN KERJA",
            showing: true,
          });
          this.$bvModal.hide("modal-status-perjanjian-kerja");
        } else {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: upload.data.message,
            showing: true,
          });
          this.$bvModal.hide("modal-status-perjanjian-kerja");
        }
      } else {
        vm.button = "Simpan";
        vm.busy = false;
        vm.$emit("alert", {
          variant: "danger",
          msg: "TERJADI KESALAHAN PADA SERVER",
          showing: true,
        });
        this.$bvModal.hide("modal-status-perjanjian-kerja");
      }
    },
  },
  watch: {
    detail: function (newVal, oldVal) {
      let vm = this;
      console.log(this.data);
      if (oldVal != newVal) {
        for (let i = 0; i < vm.options.length; i++) {
          // console.log(vm.data.statusPerjanjianAktif, vm.options[i].status);
          if (vm.data.statusPerjanjianAktif == vm.options[i].status) {
            vm.dataEdit.status = vm.options[i];
          }
        }
      }
    },
  },
};
</script>