<template>
  <div>
    <b-modal
      id="modal-detail"
      size="lg"
      centered
      scrollable
      title="Detail Perjanjian Karyawan"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <div
        style="
          text-align: center;
          padding: 2%;
          font-weight: 600;
          font-size: 21px;
        "
      >
        {{ data.nomorSuratPerjanjian }}
      </div>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table-simple borderless small>
            <b-tbody>
              <b-tr>
                <b-td style="width: 35%">Nama</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaKaryawan }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Posisi</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaPosisi }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Divisi</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaDivisi }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Jenis Perjanjian</b-td>
                <b-td>:</b-td>
                <b-td
                  ><span v-if="data.statusPerjanjian == 0"> Kontrak</span>
                  <span v-if="data.statusPerjanjian == 1"> Permanen</span>
                  <span v-if="data.statusPerjanjian == 2"> Alih Daya</span></b-td
                >
              </b-tr>

              <b-tr>
                <b-td>Status Perjanjian</b-td>
                <b-td>:</b-td>
                <b-td
                  ><span v-if="data.statusPerjanjianAktif == 0">Aktif</span>
                  <span v-if="data.statusPerjanjianAktif == 1"
                    >Habis Kontrak</span
                  >
                  <span v-if="data.statusPerjanjianAktif == 2"
                    >Diberhentikan</span
                  >
                  <span v-if="data.statusPerjanjianAktif == 3">Resign</span>
                  <span v-if="data.statusPerjanjianAktif == 4"
                    >Diperpanjang</span
                  >
                  <span v-if="data.statusPerjanjianAktif == 5"
                    >Mutasi Jabatan</span
                  >
                  <span v-if="data.statusPerjanjianAktif == 6">Meninggal</span>
                  <span v-if="data.statusPerjanjianAktif == 7">
                    Tanpa Keterangan
                  </span>
                  <span v-if="data.statusPerjanjianAktif == 8">
                    Belum Aktif
                  </span></b-td
                >
              </b-tr>

              <b-tr>
                <b-td>Tanggal Mulai Kontrak</b-td>
                <b-td>:</b-td>
                <b-td>{{ moment(data.tanggalMulaiKontrak).format("LL") }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Lama Kontrak</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.masaKontrak }} Bulan</b-td>
              </b-tr>

              <b-tr>
                <b-td>Tanggal Berakhir Kontrak</b-td>
                <b-td>:</b-td>
                <b-td>{{
                  moment(data.tanggalSelesaiKontrak).format("LL")
                }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Gaji Pokok</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.nominalGajiPerjanjian }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Detail Kontrak</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.detailKontrak }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id";
export default {
  name: "modalDetail",
  props: ["data", "detail"],
  data() {
    return {
      moment,
      src1: "",
      src2: "",
    };
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (oldVal != newVal) {
        console.log(this.data);
        this.src1 = "";
        this.src2 = "";
        if (this.data.fotoProfilKaryawan != null) {
          this.src1 = `${ipBackend}asset/file/${this.data.fotoProfilKaryawan}`;
        }
        if (this.data.fotoKKKaryawan != null) {
          this.src2 = `${ipBackend}asset/file/${this.data.fotoKKKaryawan}`;
        }
      }
      // console.log(this.data, "iki lhoo");
    },
  },
};
</script>
