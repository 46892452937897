<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ $store.state.table.perjanjian }}</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input
                  ><CIcon name="cil-plus" />
                  {{ $store.state.table.tambahModal }}</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model.lazy="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <!-- <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template> -->
                  <template #cell(tanggalMulaiKontrak)="item">
                    {{ moment(item.item.tanggalMulaiKontrak).format("LL") }}
                  </template>
                  <template #cell(tanggalSelesaiKontrak)="item">
                    {{ moment(item.item.tanggalSelesaiKontrak).format("LL") }}
                  </template>
                  <template #cell(statusPerjanjianAktif)="item">
                    <p v-if="item.item.statusPerjanjianAktif == 0">Aktif</p>
                    <p v-if="item.item.statusPerjanjianAktif == 1">
                      Habis Kontrak
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 2">
                      Diberhentikan
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 3">Resign</p>
                    <p v-if="item.item.statusPerjanjianAktif == 4">
                      Diperpanjang
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 5">
                      Mutasi Jabatan
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 6">Meninggal</p>
                    <p v-if="item.item.statusPerjanjianAktif == 7">
                      Tanpa Keterangan
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 8">
                      Belum Aktif
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 9">Pensiun</p>
                  </template>
                  <template #cell(actions)="item">
                    <div style="display: flex">
                      <div>
                        <b-button
                          v-if="item.item.statusPerjanjianAktif == 8"
                          variant="warning"
                          class="mr-1 mb-1"
                          v-c-tooltip.hover.click="'Edit Data'"
                          v-b-modal.modal-edit
                          @click="(data = item.item), setEdit(), (edit = !edit)"
                          ><CIcon name="cil-pencil" />{{
                            item.actions
                          }}</b-button
                        >
                      </div>
                      <div>
                        <b-button
                          variant="info"
                          class="mr-1 mb-1"
                          v-c-tooltip.hover.click="'Detail'"
                          v-b-modal.modal-detail
                          @click="
                            (data = item.item), setEdit(), (detail = !detail)
                          "
                          ><CIcon name="cil-info" />{{ item.actions }}</b-button
                        >
                      </div>
                      <div>
                        <b-button
                          v-if="
                            (item.item.statusPerjanjianAktif == 0 ||
                              item.item.statusPerjanjianAktif == 8) &&
                            !item.item.isMutasi
                          "
                          variant="outline-danger"
                          class="mr-1 mb-1"
                          v-c-tooltip.hover.click="'Status'"
                          v-b-modal.modal-status-perjanjian-kerja
                          @click="
                            (data = item.item), setEdit(), (detail = !detail)
                          "
                          ><CIcon name="cil-check" />{{
                            item.actions
                          }}</b-button
                        >
                      </div>
                      <div>
                        <b-button
                          variant="success"
                          class="mr-1 mb-1"
                          v-c-tooltip.hover.click="'Upload'"
                          v-b-modal.modal-dokumen-perjanjian-kerja
                          @click="
                            (data = item.item), setEdit(), (detail = !detail)
                          "
                          ><CIcon name="cil-paperclip" />{{
                            item.actions
                          }}</b-button
                        >
                      </div>
                      <div>
                        <b-button
                          variant="secondary"
                          class="mr-1 mb-1"
                          v-c-tooltip.hover.click="'Print Data'"
                          v-b-modal.modal-print
                          @click="setPrint(item.item)"
                          ><CIcon name="cil-print" />{{
                            item.actions
                          }}</b-button
                        >
                      </div>
                      <div>
                        <b-button
                          v-if="
                            item.item.statusPerjanjianAktif == 8 &&
                            !item.item.isMutasi
                          "
                          variant="danger"
                          class="mr-1 mb-1"
                          v-c-tooltip.hover.click="'Hapus Data'"
                          v-b-modal.modal-delete
                          @click="data = item.item"
                          ><CIcon name="cil-trash" />{{
                            item.actions
                          }}</b-button
                        >
                      </div>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-row>
                  <b-col> Total Data {{ totalRows }}</b-col></b-row>
              </b-col>
              <b-col md="5" offset-md="4">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <ModalInput
      :karyawan="karyawan"
      :gapok="gapok"
      @alert="triggerAlert($event)"
      :dataMitra="dataMitra"
      :manajemen="manajemen"
    />

    <ModalEdit
      :data="data"
      :gapok="gapok"
      :edit="edit"
      :karyawan="karyawan"
      @alert="triggerAlert($event)"
      :manajemen="manajemen"
    />
    <ModalDetail :data="data" :detail="detail" />
    <ModalDokumen :data="data" :detail="detail" @alert="triggerAlert($event)" />
    <ModalStatus :data="data" :detail="detail" @alert="triggerAlert($event)" />
    <ModalDelete :data="data" :detail="detail" @alert="triggerAlert($event)" />
    <ModalPrint :printData="printData" :manajemen="manajemen" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import ModalInput from "@/component/hcms/karyawan/perjanjian/modalInput.vue";
import ModalEdit from "@/component/hcms/karyawan/perjanjian/modalEdit.vue";
import ModalDokumen from "@/component/hcms/karyawan/perjanjian/modalDokumen.vue";
import ModalStatus from "@/component/hcms/karyawan/perjanjian/modalStatus.vue";
import ModalDelete from "@/component/hcms/karyawan/perjanjian/modalDelete.vue";
import ModalPrint from "@/component/hcms/karyawan/perjanjian/modalPrint.vue";
import moment from "moment";
moment.locale("id");
import ModalDetail from "@/component/hcms/karyawan/perjanjian/modalDetail.vue";
export default {
  components: {
    ModalEdit,
    ModalDetail,
    ModalInput,
    ModalDokumen,
    ModalStatus,
    ModalDelete,
    ModalPrint,
  },
  data() {
    return {
      moment,
      showing: false,
      variant: "success",
      asuransi: [],
      dataAsuransi: "",
      msg: "",
      printData: null,
      data: "",
      edit: false,
      detail: false,
      karyawan: [],
      items: [
        {
          no: "-",
          nama: "-",
          alamat: "-",
          email: "-",
          divisi: "-",
          posisi: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nomorSuratPerjanjian",
          label: "Nomor Surat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaKaryawan",
          label: "Nama Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggalMulaiKontrak",
          label: "Tanggal Mulai Kontrak",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "masaKontrak",
          label: "Durasi (bulan)",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "tanggalSelesaiKontrak",
          label: "Habis Kontrak",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusPerjanjians",
          label: "Jenis Kontrak",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusPerjanjianAktif",
          label: "Status Perjanjian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-5 text-center",
        },
      ],
      dataMitra: [],
      gapok: [],
      manajemen: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["namaKaryawan", "nomorSuratPerjanjian", "namaMitra"],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
    this.getMitra();
    this.getGaji();
  },
  methods: {
    onFiltered(filteredItems) {
      // console.log(filteredItems)
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getMitra() {
      let listMitra = await axios.get(ipBackend + "mitra/list");
      this.dataMitra = listMitra.data.data;
    },
    setEdit() {
      let vm = this;
      for (let i = 0; i < vm.karyawan.length; i++) {
        if (vm.data.dataKaryawanId == vm.karyawan[i].dataKaryawanId) {
          vm.data.dataKaryawanIdEdit = vm.karyawan[i];
        }
      }
      vm.data.tanggalMulaiKontrakEdit = moment(
        vm.data.tanggalMulaiKontrak
      ).format("DD/MM/YYYY");
      vm.data.tanggalSelesaiKontrakEdit = moment(
        vm.data.tanggalSelesaiKontrak
      ).format("DD/MM/YYYY");
    },
    setPenempatan(x) {
      if (x.capemId) {
        x.alamatPenempatan = x.alamatCapem;
      } else {
        if (x.cabangId) {
          x.alamatPenempatan = x.alamatCabang;
        } else {
          if (x.koordinatorId) {
            x.alamatPenempatan = x.alamatKoordinator;
          } else {
            x.alamatPenempatan = x.alamatMitra;
          }
        }
      }
    },
    async setPrint(x) {
      let vm = this;
      let printdata = await axios.get(
        ipBackend +
          "suratPerjanjianKerja/cetakSuratPerjanjian/" +
          x.suratPerjanjianKerjaId
      );
      // console.log(printdata.data.data, "suratPerj");
      vm.printData = printdata.data.data;
      this.setPenempatan(vm.printData);
      vm.printData.tanggal = moment().format("LL");
      vm.printData.tanggal2 = moment(vm.printData.tanggalMulaiKontrak).format("DD-MM-YYYY");
      vm.printData.tanggalMulaiKontrakAsli = moment(vm.printData.tanggalMulaiKontrak).format("DD-MM-YYYY");
      vm.printData.tanggalLahirKaryawanPrint = moment(
        vm.printData.tanggalLahirKaryawan
      ).format("LL");
      vm.printData.namaPejabatManajemenModal = "";
      vm.printData.posisiManajemenModal = "";
    },
    async getGaji() {
      let gaji = await axios.get(ipBackend + "masterGaji/list");
      this.gapok = gaji.data.data.map((item) => {
        let x = item.nominalGaji
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return {
          ...item,
          value: item.id,
          text: item.namaGaji + " - " + "Rp. " + x,
        };
      });
    },
    async getDatas() {
      let vm = this;
      vm.tableBusy = true;
      let listKontrak = await axios.get(
        ipBackend + "suratPerjanjianKerja/list"
      );
      // console.log(listKontrak, "tes");
      vm.items = listKontrak.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
        if (x.statusPerjanjian == 0) {
          x.statusPerjanjians = "Kontrak";
        } else if (x.statusPerjanjian == 1) {
          x.statusPerjanjians = "Permanen";
        } else if (x.statusPerjanjian == 2) {
          x.statusPerjanjians = "Alih daya";
        }
      }
      vm.totalRows = vm.items.length;
      let listKaryawan = await axios.get(ipBackend + "dataKaryawan/listKaryawan");
      vm.karyawan = listKaryawan.data.data.map(item =>{
        item.namaKaryawan = item.namaKaryawan + " - " + item.penempatan
        return item
      })
      // console.log(vm.karyawan,'iki');

      let manajemen = await axios.get(ipBackend + "masterManajemen/list");
      vm.manajemen = manajemen.data.data;
      vm.tableBusy = false;
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getDatas();
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
    print(data) {
      window.open(
        ipBackend +
          "suratPerjanjianKerja/cetakSuratPerjanjian/" +
          data.suratPerjanjianKerjaId,
        "_blank"
      );
    },
  },
};
</script>
