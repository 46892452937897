<template>
  <div>
    <b-modal
      id="modal-print"
      centered
      scrollable
      title="Cetak Surat Surat"
      header-bg-color="info"
      header-text-color="light"
    >
      <b-row>
        <b-col>
          <b-form-group label-cols-md="3">
            <template v-slot:label> Jabatan Manajemen </template>
            <multiselect
              v-model="pic"
              :options="manajemen"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="id"
              @input="
                (setData.namaPejabatManajemenModal = pic.namaManajemen),
                  (setData.posisiManajemenModal = pic.jabatanManajemen)
              "
              label="jabatanManajemen"
              placeholder="-- Pilih Manajemen --"
            ></multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols-md="3">
            <template v-slot:label> Nama Manajemen </template>
            <b-form-input
              type="text"
              v-model="setData.namaPejabatManajemenModal"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                v-b-tooltip.left="
                  'Manager, Asisten Manager, Kepala Seksi, Pelaksana, Operator Telepon, Supervisor CS & Taman'
                "
                color="primary"
                @click="
                  seturl('suratPerjanjianA.docx'),
                    (gen = !gen),
                    (name = 'Surat Perjanjian.docx')
                "
              >
                Print Surat Perjanjian A 08.00-16.00 / 18.00-13.30
              </CButton>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                v-b-tooltip.left="
                  'Koordinator CS & Taman, Koordinator Mekanik, Mekanil Sipil'
                "
                color="secondary"
                @click="
                  seturl('suratPerjanjianB.docx'),
                    (gen = !gen),
                    (name = 'Surat Perjanjian.docx')
                "
              >
                Print Surat Perjanjian B 08.00-16.00 / 18.00-13.00
              </CButton>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                v-b-tooltip.left="'Petugas Kebersihan, Petugas Taman'"
                color="success"
                @click="
                  seturl('suratPerjanjianC.docx'),
                    (gen = !gen),
                    (name = 'Surat Perjanjian.docx')
                "
              >
                Print Surat Perjanjian C 06.00-16.00 / 16.00-13.00
              </CButton>
            </b-col> </b-row
          ><b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                v-b-tooltip.left="
                  'Pesuruh, Pengemudi, Koordinator Parkir, Petugas Parkir, Pelaksana Mekanik Outsourching'
                "
                color="warning"
                @click="
                  seturl('suratPerjanjianD.docx'),
                    (gen = !gen),
                    (name = 'Surat Perjanjian.docx')
                "
              >
                Print Surat Perjanjian D 07.00-17.00 / 07.00-13.00
              </CButton>
            </b-col> </b-row
          ><b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                v-b-tooltip.left="'Mekanik ME'"
                color="info"
                @click="
                  seturl('suratPerjanjianE.docx'),
                    (gen = !gen),
                    (name = 'Surat Perjanjian.docx')
                "
              >
                Print Surat Perjanjian E Pagi 08.00-19.00 / Malam 19.00-08.00
              </CButton>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                v-b-tooltip.left="'Agent Call Center'"
                color="dark"
                @click="
                  seturl('suratPerjanjianF.docx'),
                    (gen = !gen),
                    (name = 'Surat Perjanjian.docx')
                "
              >
                Print Surat Perjanjian F 4 shift
              </CButton>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                v-b-tooltip.left="'Satpam'"
                color="outline-success"
                @click="
                  seturl('suratPerjanjianG.docx'),
                    (gen = !gen),
                    (name = 'Surat Perjanjian.docx')
                "
              >
                Print Surat Perjanjian G Satpan (8 jam)
              </CButton>
            </b-col>
          </b-row>
          <!-- <b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                color="secondary"
                @click="
                  seturl('LembarPersetujuanEksternal.docx'),
                    (gen = !gen),
                    (name = `Lembar Persetujuan Eksternal ${printData.namaKaryawan}.docx`)
                "
              >
                Print Surat Persetujuan Eksternal
              </CButton>
            </b-col>
          </b-row> -->
          <b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                color="secondary"
                @click="
                  seturl('SuratPernyataan.docx'),
                    (gen = !gen),
                    (name = `Surat Pernyataan PKWT ${printData.namaKaryawan}.docx`)
                "
              >
                Print Surat Pernyataan PKWT
              </CButton>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                color="secondary"
                @click="
                  seturl('SuratPernyataanPKWTEclat.docx'),
                    (gen = !gen),
                    (name = `Surat Pernytaan PKWT Eclat ${printData.namaKaryawan}.docx`)
                "
              >
                Print Surat Pernyataan PKWT Eclat
              </CButton>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <CButton
                class="mr-1"
                block
                color="secondary"
                @click="
                  seturl('SuratPernyataanBasic.docx'),
                    (gen = !gen),
                    (name = `Template Surat Pernyataan ${printData.namaKaryawan}.docx`)
                "
              >
                Print Template Surat Pernyataan
              </CButton>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <CButton
          class="mr-1"
          color="secondary"
          @click="$bvModal.hide('modal-print')"
        >
          Batal
        </CButton>
      </template>
    </b-modal>
    <generator :data="printData" :gen="gen" :url="url" :name="name" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import generator from "@/component/DOCX/docx_generator.vue";
import moment from "moment";
import terbilang from "@/component/DOCX/terbilang.js";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "moment/locale/id";
export default {
  components: {
    generator,
    Multiselect,
  },
  name: "modalPrint",
  props: ["printData", "manajemen"],
  data() {
    return {
      ipBackend,
      gen: false,
      name: "",
      url: "",
      pic: null,
      setData: {
        namaPejabatManajemenModal: null,
        posisiManajemenModal: null,
      },
    };
  },
  methods: {
    async seturl(x) {
      let vm = this;
      if (vm.setData.namaPejabatManajemenModal) {
        vm.printData.namaPejabatManajemen =
          vm.setData.namaPejabatManajemenModal;
      }
      if (vm.setData.posisiManajemenModal) {
        vm.printData.posisiManajemen = vm.setData.posisiManajemenModal;
      }
      vm.url = this.ipBackend + "asset/docx/" + x;
      //   console.log(this.url);
    },
  },
};
</script>
