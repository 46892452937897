<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Perjanjian Karyawan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            No. Surat Perjanjian <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="$v.data.nomorSuratPerjanjian.$model"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            No. Surat Perintah <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="$v.data.nomorSuratPerintah.$model"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jenis Perjanjian <span class="text-danger">*</span>
          </template>
          <b-form-select
            :options="jenis"
            type="text"
            v-model="$v.data.statusPerjanjian.$model"
            style="width: 100%"
          ></b-form-select>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Data Karyawan <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.dataKaryawanId.$model"
            :options="karyawan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="dataKaryawanId"
            label="namaKaryawan"
            placeholder="-- Pilih Karyawan --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Divisi <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.masterDivisiId.$model"
            :options="divisi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="id"
            @input="
              getPosisi(data.masterDivisiId),
                (data.namaDivisi = data.masterDivisiId.namaDivisi),
                (data.masterPosisiId = '')
            "
            label="namaDivisi"
            placeholder="-- Pilih Divisi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Posisi <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.masterPosisiId.$model"
            :options="posisi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="id"
            @input="
              (data.namaPosisi = data.masterPosisiId.namaPosisi),
                (data.jabatanBaru = data.masterPosisiId.namaPosisi)
            "
            label="namaPosisi"
            placeholder="-- Pilih Posisi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Mitra <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.mitraId.$model"
            :state="checkIfValid('mitraId')"
            :options="dataMitra"
            @input="getKoordinator(data.mitraId), setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaMitra"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Koordinator <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="data.koordinatorId"
            :options="koordinator"
            @input="getCabang(data.koordinatorId), setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKoordinator"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Nama Cabang" label-cols-md="3">
          <multiselect
            v-model="data.cabangId"
            :options="cabang"
            @input="getCapem(data.cabangId), setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaCabang"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Nama Capem" label-cols-md="3">
          <multiselect
            v-model="data.capemId"
            :options="capem"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaCapem"
            placeholder="-- Pilih --"
            @input="setPenempatan()"
          ></multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Penempatan <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="data.penempatan"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Gaji Pokok" label-cols-md="3">
          <b-form-select
            :options="gapok"
            v-model="data.masterGajiId"
            @input="setNominal(data.masterGajiId)"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Nominal Gaji Pokok" label-cols-md="3">
          <b-form-input
            :disabled="
              data.masterGajiId != 'fc39dbe3-4bfa-4400-8618-0eddf4a0fc65'
            "
            type="number"
            :options="gapok"
            v-model="data.nominalGajiPerjanjian"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jabatan Manajemen <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.posisiManajemenId.$model"
            :options="manajemen"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="id"
            @input="
              (data.namaPejabatManajemen =
                data.posisiManajemenId.namaManajemen),
                (data.posisiManajemen = data.posisiManajemenId.jabatanManajemen)
            "
            label="jabatanManajemen"
            placeholder="-- Pilih Manajemen --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Manajemen <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="data.namaPejabatManajemen"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Mulai Kontrak <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="$v.data.tanggalMulaiKontrak.$model"
            style="width: 100%"
            @input="setExpire()"
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Durasi Kontrak (bulan) <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            v-model="$v.data.masaKontrak.$model"
            style="width: 100%"
            @input="setExpire()"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Selesai Kontrak <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="data.tanggalSelesaiKontrak"
            style="width: 100%"
            :placeholder="place"
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Detail Kontrak <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            v-model="data.detailKontrak"
            style="width: 100%"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          @click="simpan()"
          :disabled="!isValid || busy"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: { Multiselect, DatePicker },
  props: ["karyawan", "dataMitra", "gapok",'manajemen'],
  name: "modalInput",
  data() {
    return {
      moment,
      koordinator: [],
      cabang: [],
      capem: [],
      data: {
        tanggalDibuatSurat: "",
        statusPerjanjian: "",
        nomorSuratPerjanjian: "",
        nomorSuratPerintah: "",
        dataKaryawanId: "",
        tanggalMulaiKontrak: "",
        tanggalSelesaiKontrak: "",
        masaKontrak: "",
        statusPerjanjian: 8,
        statusKerjaKaryawan: 1,
        detailKontrak: "",
        masterDivisiId: "",
        namaDivisi: "",
        masterPosisiId: "",
        namaPosisi: "",
        posisiManajemen: "",
        namaPejabatManajemen: "",
        tanggalDibuatSurat: "",
        statusPerjanjianAktif: 0,
        mitraId: "",
        koordinatorId: "",
        cabangId: "",
        capemId: "",
        penempatan: "",
        masterGajiId: "",
        nominalGajiPerjanjian: "",
      },
      jenis: [
        {
          value: 0,
          text: "Kontrak",
        },
        {
          value: 1,
          text: "Permanen",
        },
        {
          value: 2,
          text: "Alih Daya / Outsourching",
        },
      ],
      divisi: [],
      posisi: [],
      place: "",
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      nomorSuratPerjanjian: {
        required,
      },
      nomorSuratPerintah: {
        required,
      },
      dataKaryawanId: {
        required,
      },
      statusPerjanjian: {
        required,
      },
      tanggalMulaiKontrak: {
        required,
      },
      masaKontrak: {
        required,
      },
      masterDivisiId: {
        required,
      },
      masterPosisiId: {
        required,
      },
      mitraId: {
        required,
      },
      tanggalSelesaiKontrak: {
        required,
      },
      // koordinatorId: {
      //   required,
      // },
      posisiManajemenId: {
        required,
      },
    },
  },
  mounted() {
    this.getDivisi();
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir2(x) {
      // console.log(x);
      vm.data.statusKerjaKaryawan = x.statusKerjaKaryawan;
    },
    setPenempatan() {
      let vm = this;
      // console.log(vm.data.mitraId);
      if (vm.data.capemId) {
        vm.data.penempatan = vm.data.capemId.namaCapem;
      } else {
        if (vm.data.cabangId) {
          vm.data.penempatan = vm.data.cabangId.namaCabang;
        } else {
          if (vm.data.koordinatorId) {
            vm.data.penempatan = vm.data.koordinatorId.namaKoordinator;
          } else {
            vm.data.penempatan = `${vm.data.mitraId.namaMitra}`;
          }
        }
      }
    },
    async getKoordinator(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      //   console.log(koordinator.data.data, "ini koordinator");
      this.koordinator = koordinator.data.data;
    },
    async getCabang(x) {
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.cabang = cabang.data.data;
    },
    async getCapem(x) {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.capem = capem.data.data;
    },
    async getDivisi() {
      let vm = this;
      let divisi = await axios.get(ipBackend + "masterDivisi/list");
      vm.divisi = divisi.data.data;

      // let manajemen = await axios.get(ipBackend + "masterManajemen/list");
      // vm.manajemen = manajemen.data.data;

      // console.log(manajemen.data.data, "iki");
    },
    async getPosisi(x) {
      let vm = this;
      let posisi = await axios.get(
        ipBackend + "masterPosisi/listPosisiByMasterDivisiId/" + x.id
      );
      vm.posisi = posisi.data.data;
    },
    setNominal(x) {
      this.gapok.forEach((item) => {
        if (item.id == x) {
          this.data.nominalGajiPerjanjian = item.nominalGaji;
          this.data.namaGaji = item.namaGaji;
        }
      });
    },
    setExpire() {
      let vm = this;
      vm.data.tanggalSelesaiKontrak = moment(vm.data.tanggalMulaiKontrak)
        .add(vm.data.masaKontrak, "month")
        .subtract(1, "days");
      vm.place = moment(vm.data.tanggalMulaiKontrak)
        .add(vm.data.masaKontrak, "month")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },
    reset() {
      this.data = {
        tanggalDibuatSurat: "",
        nomorSurat: "",
        statusPerjanjian: "",
        dataKaryawanId: "",
        tanggalMulaiKontrak: "",
        tanggalSelesaiKontrak: "",
        masaKontrak: "",
        statusPerjanjian: 0,
        statusKerjaKaryawan: 1,
        detailKontrak: "",
        masterDivisiId: "",
        namaDivisi: "",
        masterPosisiId: "",
        namaPosisi: "",
        posisiManajemen: "",
        namaPejabatManajemen: "",
        tanggalDibuatSurat: "",
        statusPerjanjianAktif: 0,
        mitraId: "",
        koordinatorId: "",
        cabangId: "",
        capemId: "",
        penempatan: "",
        masterGajiId: "",
        nominalGajiPerjanjian: "",
      };
    },
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.tanggalDibuatSurat = moment();
      vm.data.dataKaryawanId = vm.data.dataKaryawanId.dataKaryawanId;
      vm.data.masterDivisiId = vm.data.masterDivisiId.id;
      vm.data.masterPosisiId = vm.data.masterPosisiId.masterPosisiId;
      if (vm.data.mitraId) {
        vm.data.mitraId = vm.data.mitraId.id;
      }
      if (vm.data.koordinatorId) {
        vm.data.koordinatorId = vm.data.koordinatorId.koordinatorId;
      }
      if (vm.data.cabangId) {
        vm.data.cabangId = vm.data.cabangId.cabangId;
      }
      if (vm.data.capemId) {
        vm.data.capemId = vm.data.capemId.capemId;
      }
      console.log(vm.data);
      axios
        .post(ipBackend + "suratPerjanjianKerja/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.reset();
            vm.$v.$reset();
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN KONTRAK KARYAWAN",
              showing: true,
            });
            this.$bvModal.hide("modal-input");
          } else {
            vm.button = "Simpan";
            this.$bvModal.hide("modal-input");
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
