<template>
  <div>
    <b-modal
      id="modal-delete"
      size="lg"
      centered
      title="Hapus Dokumen Surat Perjanjian Kerja"
      header-bg-variant="danger"
      header-text-variant="light"
    >
    Apakah anda yakin akan menghapus Surat Perjanjian Kerja nomor <span class="text-danger"><strong>{{data.nomorSurat}}</strong></span> atas nama  <span class="text-danger"><strong>{{data.namaKaryawan}}</strong></span> ?

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-delete')"
        >
          {{ $store.state.form.batal }}
        </b-button>
        <b-button variant="primary" :disabled="busy" @click="upload()">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: { Multiselect },
  props: ["data", "detail"],
  data() {
    return {
      dataEdit: {
        status: "",
        statusPerjanjianAktif: "",
        id: "",
      },
      busy: false,
      button: "Hapus",
      options: [
        { status: 0, text: "Aktif" },
        { status: 1, text: "Selesai /Habis Kontrak" },
        { status: 2, text: "Diberhentikan" },
        { status: 3, text: "Resign" },
        // { status: 4, text: "Diperpanjang" },
        // { status: 5, text: "Mutasi Jabatan" },
        { status: 6, text: "Meninggal" },
        { status: 7, text: "Hilang / Tanpa Keterangan" },
      ],
    };
  },
  methods: {
    async upload() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.id = vm.data.suratPerjanjianKerjaId
      let upload = await axios.post(
        ipBackend + "suratPerjanjianKerja/delete",
        vm.data
      );
      if (upload.data.status == 200) {
        if (upload.data.message == "sukses") {
          vm.button = "Hapus";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "success",
            msg: "BERHASIL MENGHAPUS SURAT PERJANJIAN KERJA",
            showing: true,
          });
          this.$bvModal.hide("modal-delete");
        } else {
          vm.button = "Hapus";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: upload.data.message,
            showing: true,
          });
          this.$bvModal.hide("modal-delete");
        }
      } else {
        vm.button = "Hapus";
        vm.busy = false;
        vm.$emit("alert", {
          variant: "danger",
          msg: "TERJADI KESALAHAN PADA SERVER",
          showing: true,
        });
        this.$bvModal.hide("modal-delete");
      }
    },
  },
  watch: {
    detail: function (newVal, oldVal) {
      let vm = this;
      if (oldVal != newVal) {
        for (let i = 0; i < vm.options.length; i++) {
          // console.log(vm.data.statusPerjanjianAktif, vm.options[i].status);
          if (vm.data.statusPerjanjianAktif == vm.options[i].status) {
            vm.dataEdit.status = vm.options[i];
          }
        }
      }
    },
  },
};
</script>