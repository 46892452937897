<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Edit Data Perjanjian Karyawan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            No. Surat <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="$v.data.nomorSuratPerjanjian.$model"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jenis Perjanjian <span class="text-danger">*</span>
          </template>
          <b-form-select
            :options="jenis"
            type="text"
            v-model="$v.data.statusPerjanjian.$model"
            style="width: 100%"
          ></b-form-select>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Karyawan <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            disabled
            v-model="data.namaKaryawan"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Divisi <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.masterDivisiId.$model"
            :options="divisi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="id"
            @input="
              getPosisi(data.masterDivisiId),
                (data.namaDivisi = data.masterDivisiId.namaDivisi)
            "
            label="namaDivisi"
            placeholder="-- Pilih Karyawan --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Posisi <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.masterPosisiId.$model"
            :options="posisi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="id"
            @input="data.namaPosisi = data.masterPosisiId.namaPosisi"
            label="namaPosisi"
            placeholder="-- Pilih Posisi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jabatan Manajemen <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="data.posisiManajemenId"
            :options="manajemen"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="id"
            @input="
              (data.namaPejabatManajemen =
                data.posisiManajemenId.namaManajemen),
                (data.posisiManajemen = data.posisiManajemenId.jabatanManajemen)
            "
            label="jabatanManajemen"
            placeholder="-- Pilih Posisi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Manajemen <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="data.namaPejabatManajemen"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Gaji Pokok" label-cols-md="3">
          <b-form-select
            :options="gapok"
            v-model="data.masterGajiIdPerjanjian"
            @input="setNominal(data.masterGajiIdPerjanjian)"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Nominal Gaji Pokok" label-cols-md="3">
          <b-form-input
            :disabled="
              data.masterGajiIdPerjanjian != 'fc39dbe3-4bfa-4400-8618-0eddf4a0fc65'
            "
            type="number"
            :options="gapok"
            v-model="data.nominalGajiPerjanjian"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Mulai Kontrak <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="data.tanggalMulaiKontrak"
            style="width: 100%"
            :placeholder="data.tanggalMulaiKontrakEdit"
            @input="setExpire()"
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Durasi Kontrak (bulan) <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            v-model="data.masaKontrak"
            style="width: 100%"
            @input="setExpire()"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Selesai Kontrak <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="data.tanggalSelesaiKontrak"
            style="width: 100%"
            :placeholder="data.tanggalSelesaiKontrakEdit"
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Detail Kontrak <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            v-model="data.detailKontrak"
            style="width: 100%"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          @click="simpan()"
          :disabled="!isValid || busy"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: { Multiselect, DatePicker },
  props: ["karyawan", "data", "edit", "gapok", "manajemen"],
  name: "modalInput",
  data() {
    return {
      moment,
      busy: false,
      button: "Simpan",
      jenis: [
        {
          value: 0,
          text: "Kontrak",
        },
        {
          value: 1,
          text: "Permanen",
        },
        {
          value: 2,
          text: "Alih Daya / Outsourching",
        },
      ],
      divisi: [],
      posisi: [],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      statusPerjanjian: {
        required,
      },
      tanggalMulaiKontrak: {
        required,
      },
      masaKontrak: {
        required,
      },
      nomorSuratPerjanjian: {
        required,
      },
      masterDivisiId: {
        required,
      },
      masterPosisiId: {
        required,
      },
      // posisiManajemenId: {
      //   required,
      // },
    },
  },
  mounted() {
    this.getDivisi();
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getDivisi() {
      let vm = this;
      let divisi = await axios.get(ipBackend + "masterDivisi/list");
      vm.divisi = divisi.data.data;

      // let manajemen = await axios.get(ipBackend + "masterManajemen/list");
      // vm.manajemen = manajemen.data.data;

      // console.log(manajemen.data.data, "iki");
    },
    async getPosisi(x) {
      let vm = this;
      let posisi = await axios.get(
        ipBackend + "masterPosisi/listPosisiByMasterDivisiId/" + x.id
      );
      // console.log(posisi.data.data);

      vm.posisi = posisi.data.data;
    },
    setNominal(x) {
      console.log(x);
      this.gapok.forEach((item) => {
        if (item.id == x) {
          this.data.nominalGajiPerjanjian = item.nominalGaji;
          this.data.namaGaji = item.namaGaji;
        }
      });
    },
    setExpire() {
      let vm = this;
      vm.data.tanggalSelesaiKontrak = moment(vm.data.tanggalMulaiKontrak)
        .add(vm.data.masaKontrak, "month")
        .subtract(1, "days");
      vm.data.tanggalSelesaiKontrakEdit = moment(vm.data.tanggalMulaiKontrak)
        .add(vm.data.masaKontrak, "month")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.id = vm.data.suratPerjanjianKerjaId;
      vm.data.masterDivisiId = vm.data.masterDivisiId.id;
      vm.data.masterPosisiId = vm.data.masterPosisiId.masterPosisiId;
      vm.data.masterGajiId = vm.data.masterGajiIdPerjanjian
      console.log(vm.data,'edit');
      axios
        .post(ipBackend + "suratPerjanjianKerja/update", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENGUBAH KONTRAK KARYAWAN",
              showing: true,
            });
            vm.$bvModal.hide("modal-edit");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
  watch: {
    edit: async function (newVal, oldVal) {
      let vm = this;
      console.log(this.data);
      vm.busy = true;
      let posisi = await axios.get(
        ipBackend +
          "masterPosisi/listPosisiByMasterDivisiId/" +
          vm.data.masterDivisiId
      );

      vm.posisi = posisi.data.data;
      if (newVal != oldVal) {
        await vm.divisi.forEach((ele) => {
          if (ele.id == vm.data.masterDivisiId) {
            vm.data.masterDivisiId = ele;
          }
        });

        await vm.manajemen.forEach((ele) => {
          if (vm.data.posisiManajemen == ele.jabatanManajemen) {
            vm.data.posisiManajemenId = ele;
          }
        });
        await vm.posisi.forEach((ele) => {
          if (ele.masterPosisiId == vm.data.masterPosisiId) {
            vm.data.masterPosisiId = ele;
          }
        });
      }
      vm.busy = false;
    },
  },
};
</script>
