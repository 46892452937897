var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-print","centered":"","scrollable":"","title":"Cetak Surat Surat","header-bg-color":"info","header-text-color":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('CButton',{staticClass:"mr-1",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$bvModal.hide('modal-print')}}},[_vm._v(" Batal ")])]},proxy:true}])},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Jabatan Manajemen ")]},proxy:true}])},[_c('multiselect',{attrs:{"options":_vm.manajemen,"multiple":false,"searchable":true,"close-on-select":true,"show-labels":false,"track-by":"id","label":"jabatanManajemen","placeholder":"-- Pilih Manajemen --"},on:{"input":function($event){(_vm.setData.namaPejabatManajemenModal = _vm.pic.namaManajemen),
                (_vm.setData.posisiManajemenModal = _vm.pic.jabatanManajemen)}},model:{value:(_vm.pic),callback:function ($$v) {_vm.pic=$$v},expression:"pic"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nama Manajemen ")]},proxy:true}])},[_c('b-form-input',{staticStyle:{"width":"100%"},attrs:{"type":"text"},model:{value:(_vm.setData.namaPejabatManajemenModal),callback:function ($$v) {_vm.$set(_vm.setData, "namaPejabatManajemenModal", $$v)},expression:"setData.namaPejabatManajemenModal"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:(
                'Manager, Asisten Manager, Kepala Seksi, Pelaksana, Operator Telepon, Supervisor CS & Taman'
              ),expression:"\n                'Manager, Asisten Manager, Kepala Seksi, Pelaksana, Operator Telepon, Supervisor CS & Taman'\n              ",modifiers:{"left":true}}],staticClass:"mr-1",attrs:{"block":"","color":"primary"},on:{"click":function($event){_vm.seturl('suratPerjanjianA.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = 'Surat Perjanjian.docx')}}},[_vm._v(" Print Surat Perjanjian A 08.00-16.00 / 18.00-13.30 ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:(
                'Koordinator CS & Taman, Koordinator Mekanik, Mekanil Sipil'
              ),expression:"\n                'Koordinator CS & Taman, Koordinator Mekanik, Mekanil Sipil'\n              ",modifiers:{"left":true}}],staticClass:"mr-1",attrs:{"block":"","color":"secondary"},on:{"click":function($event){_vm.seturl('suratPerjanjianB.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = 'Surat Perjanjian.docx')}}},[_vm._v(" Print Surat Perjanjian B 08.00-16.00 / 18.00-13.00 ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:('Petugas Kebersihan, Petugas Taman'),expression:"'Petugas Kebersihan, Petugas Taman'",modifiers:{"left":true}}],staticClass:"mr-1",attrs:{"block":"","color":"success"},on:{"click":function($event){_vm.seturl('suratPerjanjianC.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = 'Surat Perjanjian.docx')}}},[_vm._v(" Print Surat Perjanjian C 06.00-16.00 / 16.00-13.00 ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:(
                'Pesuruh, Pengemudi, Koordinator Parkir, Petugas Parkir, Pelaksana Mekanik Outsourching'
              ),expression:"\n                'Pesuruh, Pengemudi, Koordinator Parkir, Petugas Parkir, Pelaksana Mekanik Outsourching'\n              ",modifiers:{"left":true}}],staticClass:"mr-1",attrs:{"block":"","color":"warning"},on:{"click":function($event){_vm.seturl('suratPerjanjianD.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = 'Surat Perjanjian.docx')}}},[_vm._v(" Print Surat Perjanjian D 07.00-17.00 / 07.00-13.00 ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:('Mekanik ME'),expression:"'Mekanik ME'",modifiers:{"left":true}}],staticClass:"mr-1",attrs:{"block":"","color":"info"},on:{"click":function($event){_vm.seturl('suratPerjanjianE.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = 'Surat Perjanjian.docx')}}},[_vm._v(" Print Surat Perjanjian E Pagi 08.00-19.00 / Malam 19.00-08.00 ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:('Agent Call Center'),expression:"'Agent Call Center'",modifiers:{"left":true}}],staticClass:"mr-1",attrs:{"block":"","color":"dark"},on:{"click":function($event){_vm.seturl('suratPerjanjianF.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = 'Surat Perjanjian.docx')}}},[_vm._v(" Print Surat Perjanjian F 4 shift ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:('Satpam'),expression:"'Satpam'",modifiers:{"left":true}}],staticClass:"mr-1",attrs:{"block":"","color":"outline-success"},on:{"click":function($event){_vm.seturl('suratPerjanjianG.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = 'Surat Perjanjian.docx')}}},[_vm._v(" Print Surat Perjanjian G Satpan (8 jam) ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{staticClass:"mr-1",attrs:{"block":"","color":"secondary"},on:{"click":function($event){_vm.seturl('SuratPernyataan.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = "Surat Pernyataan PKWT " + (_vm.printData.namaKaryawan) + ".docx")}}},[_vm._v(" Print Surat Pernyataan PKWT ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{staticClass:"mr-1",attrs:{"block":"","color":"secondary"},on:{"click":function($event){_vm.seturl('SuratPernyataanPKWTEclat.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = "Surat Pernytaan PKWT Eclat " + (_vm.printData.namaKaryawan) + ".docx")}}},[_vm._v(" Print Surat Pernyataan PKWT Eclat ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('CButton',{staticClass:"mr-1",attrs:{"block":"","color":"secondary"},on:{"click":function($event){_vm.seturl('SuratPernyataanBasic.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = "Template Surat Pernyataan " + (_vm.printData.namaKaryawan) + ".docx")}}},[_vm._v(" Print Template Surat Pernyataan ")])],1)],1)],1)],1)],1),_c('generator',{attrs:{"data":_vm.printData,"gen":_vm.gen,"url":_vm.url,"name":_vm.name}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }