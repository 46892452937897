<template>
  <div>
    <b-modal
      id="modal-dokumen-perjanjian-kerja"
      size="lg"
      centered
      title="Upload Dokumen Surat Perjanjian Kerja"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group
          label="Upload Surat Perjanjian Kerja"
          description="Upload surat perjanjian kerja yang telah ditandatangani"
          label-cols-md="3"
        >
          <b-form-file accept=".jpg,.jpeg,.png,.pdf" id="file1" ref="file1" @input="handleFile()"></b-form-file>
        </b-form-group>
      </b-form>
       <center>
         <div v-if="isPdf">
          {{ currentPage }} / {{ pageCount }}
          <pdf
            :src="src1"
            @num-pages="pageCount = $event"
            @page-loaded="currentPage = $event"
          ></pdf>
          <!-- <div class="d-flex justify-content-around mt-2 mb-2">
            <b-button @click="add()">Sebelum</b-button>
            <b-button @click="sub()">berikut</b-button>
          </div> -->
        </div>
        
        <div v-else>
          <img   :src="src1" alt="" class="imgss" style="width: 100%; height: auto; margin-bottom:7mm" />
        </div>
        
      </center>
      <center><b-button @click="download(data)" v-if="data.fotoSurat != null">Download</b-button></center>
      
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-dokumen-perjanjian-kerja')"
        >
          {{ $store.state.form.batal }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
import {ipBackend} from "@/ipBackend"
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import pdf from "vue-pdf"
export default {
  components: {
    DatePicker,
    pdf
  },
  props:['data','detail'],
  data() {
    return {
      file1:"",
      src1:"",
      isPdf: false,
      currentPage: 0,
      pageCount: 0
    };
  },
  methods:{
    handleFile(){
      this.file1 = this.$refs.file1.files[0]
      this.src1 = URL.createObjectURL(this.file1)
      this.upload()
    },
    async upload(){
      let vm = this
      let formData = new FormData
      formData.append('id', vm.data.suratPerjanjianKerjaId)
      formData.append('file1', vm.file1)
      let upload = await axios.post(ipBackend + 'suratPerjanjianKerja/updateFotoSurat', formData)
      if(upload.data.status == 200){
        if(upload.data.message == 'sukses'){
          vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL UPLOAD SURAT PERJANJIAN KERJA",
              showing: true,
            });
            this.$bvModal.hide("modal-dokumen-perjanjian-kerja");
        }else{
          vm.$emit("alert", {
              variant: "danger",
              msg: upload.data.message,
              showing: true,
            });
            this.$bvModal.hide("modal-dokumen-perjanjian-kerja");
        }
      }else{
        vm.$emit("alert", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
            this.$bvModal.hide("modal-dokumen-perjanjian-kerja");
      }
    },
    getType(x){
      var parts = x.split('.');
      return parts[parts.length - 1];
    },
    download(data){
      window.open(
        ipBackend +
          "asset/file/" + data.fotoSurat,
        "_blank"
      );
    },
    add(){
      if(this.currentPage < this.pageCount ){
        this.currentPage ++
        console.log(this.currentPage)
      }
    },
    sub(){
      if(this.currentPage > 1){
        this.currentPage --
      }
    }
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (oldVal != newVal) {
        this.src1 = "";
        if (this.data.fotoSurat != null) {
          let ext = this.getType(this.data.fotoSurat)
          if(ext.toLowerCase() == 'pdf'){
            this.isPdf = true
          }else{
            this.isPdf = false
          }
          this.src1 = `${ipBackend}asset/file/${this.data.fotoSurat}`;
        }
      }
    },
  },
};
</script>